import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SelectModule } from 'primeng/select';
import { debounceTime, map, Subject } from 'rxjs';

import { PaginatedSiUnitsList } from '@offconon/core-api';
import { SiUnits } from '@offconon/core-api';
import { SystemService } from '@offconon/core-api';
import { SiteLanguageService } from '@offconon/shared/utils/services';

@Component({
  selector: 'offconon-si-units-dropdown-select',
  templateUrl: './si-units-dropdown-select.component.html',
  imports: [SelectModule, FormsModule, SharedModule, ProgressSpinnerModule, TranslateModule],
})
export class SiUnitsDropdownSelectComponent implements OnInit, OnChanges {
  private systemService = inject(SystemService);
  private siteLanguageService = inject(SiteLanguageService);

  @Output() selectedSiUnitEvent = new EventEmitter<any>();
  @Input() selected_id: any;
  @Input() unit_type: any;
  @Input() selectFirst = true;

  siunits: any[] = [];
  selectedSiUnit: any;

  actual_language_id = 71;

  private destroyRef = inject(DestroyRef);

  page = 1;
  pageSize = 50;
  last = 0;
  searchString = '';
  searchSubject = new Subject();
  loading = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['unit_type']) this.getsiUnits();
  }

  ngOnInit(): void {
    this.actual_language_id = this.siteLanguageService.actualSiteLanguageId();
    this.getsiUnits();

    this.searchSubject
      .pipe(
        debounceTime(2000),
        map(() => {
          this.getsiUnits();
        }),
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
    if (this.selected_id) {
      this.systemService
        .systemSiUnitsList(1, 1, {
          unit_type: this.unit_type,
          language_id: this.actual_language_id,
        })
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (res: any) => {
            if (res.results && res?.results?.length > 0) {
              if (this.selectFirst) this.selectedSiUnit = res.results[0];
              this.siunits = [...this.siunits, ...res.results];
            }
          },
        });
    } else {
      if (this.selectFirst) this.selectedSiUnit = this.siunits[0];
    }
  }

  refreshList(event: { first: number; last: number }) {
    if (this.last < event.last) {
      this.last = event.last;
      if (event.last % this.pageSize === 0) {
        this.page++;
        this.getsiUnits();
      }
    }
  }

  onSearch(event: any) {
    this.searchString = event.filter;
    this.page = 1;
    this.last = 0;
    this.siunits = [];
    this.searchSubject.next(this.searchString);
  }

  getsiUnits() {
    const searchString = this.searchString.trim() === '' ? undefined : this.searchString;
    this.systemService
      .systemSiUnitsList(this.page, this.pageSize, {
        language_id: this.actual_language_id,
        unit_type: this.unit_type,
        universal_translations: [searchString],
      })
      .pipe(
        map((res: PaginatedSiUnitsList | any) => {
          return res?.results?.map((item: SiUnits | any) => {
            return {
              name: item?.name ? item?.name : '-',
              id: item.id,
            };
          });
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((res: any) => {
        this.siunits = res;

        if (!this.selected_id && this.selectFirst) this.selectedSiUnit = this.siunits[0];

        this.selectedSiUnitEvent.emit(this.selectedSiUnit);
      });
  }

  select() {
    this.selectedSiUnitEvent.emit(this.selectedSiUnit);
  }
}
