<div data-testid="range-row__container">
  @for (row of rows; track row; let i = $index) {
    <div class="flex flex-wrap pb-5 pt-3" data-testid="range-row__row-container">
      <div class="w-full md:w-1/2" data-testid="range-row__from-value-container">
        <div class="flex flex-col gap-2 mb-4 mt-4 md:m-2">
          <label class="font-medium"> {{ 'From this number' | translate }}</label>
          <p-inputNumber
            buttonLayout="horizontal"
            inputId="horizontal"
            spinnerMode="horizontal"
            decrementButtonClass="p-button-text p-button-danger py-1 px-1"
            incrementButtonClass="p-button-text p-button-success py-1 px-1"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            data-testid="range-row__from-value-input"
            [inputStyleClass]="'w-full text-center py-3 px-1 border-transparent text-blue-500'"
            [style]="{ width: '100%' }"
            [styleClass]="'  '"
            [showButtons]="true"
            [step]="0.01"
            [min]="0.01"
            [max]="9000000000000"
            suffix="  {{ si_unit_name }}"
            [(ngModel)]="rows[i].from_value"
            (ngModelChange)="ruleChanged()" />
          <small class="block mt-1 leading-6 text-xs"
            >{{ 'Select the starting number of the range.' | translate }}
            {{
              'Note that the start number must be 0.01 greater than the end number of the last row range.'
                | translate
            }}
          </small>
        </div>
      </div>

      <div class="w-full md:w-1/2" data-testid="range-row__to-value-container">
        <div class="flex flex-col gap-2 mb-4 mt-4 md:m-2">
          <label class="font-medium"> {{ 'Up to this number' | translate }}</label>
          <p-inputNumber
            buttonLayout="horizontal"
            inputId="horizontal"
            spinnerMode="horizontal"
            decrementButtonClass="p-button-text p-button-danger py-1 px-1"
            incrementButtonClass="p-button-text p-button-success py-1 px-1"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            data-testid="range-row__to-value-input"
            [inputStyleClass]="'w-full text-center py-3 px-1 border-transparent text-blue-500'"
            [style]="{ width: '100%' }"
            [styleClass]="'  '"
            [showButtons]="true"
            [step]="0.01"
            [min]="0.01"
            [max]="9000000000000"
            suffix="  {{ si_unit_name }}"
            [(ngModel)]="rows[i].to_value"
            (ngModelChange)="ruleChanged()" />
          <small class="block mt-1 leading-6 text-xs">{{
            'Select the end number of the range.' | translate
          }}</small>
        </div>
      </div>

      @if (i !== 0) {
        <div class="field mb-4 w-full pt-4" data-testid="range-row__delete-container">
          <div class="flex justify-end flex-wrap card-container">
            <div class="flex items-center justify-center m-2">
              <p-button
                icon="pi pi-trash"
                iconPos="left"
                severity="danger"
                data-testid="range-row__delete-button"
                [styleClass]="'w-full'"
                label="{{ 'Delete row' | translate }}"
                [rounded]="true"
                (click)="deleteRow(i)" />
            </div>
          </div>
        </div>
      }
    </div>
  }

  <div class="flex items-center justify-center m-2" data-testid="range-row__add-container">
    <p-button
      icon="pi pi-plus"
      iconPos="left"
      data-testid="range-row__add-button"
      [styleClass]="'w-full'"
      label="{{ 'Add row' | translate }}"
      [rounded]="true"
      (click)="addRow()" />
  </div>
</div>
