@if (!loading) {
  @if (selectedSiUnit) {
    <p-select
      optionLabel="name"
      filterBy="name"
      [styleClass]="'w-full'"
      [virtualScrollItemSize]="pageSize"
      [virtualScroll]="true"
      [appendTo]="'body'"
      [lazy]="true"
      [options]="siunits"
      [filter]="true"
      [showClear]="false"
      [filterValue]="searchString"
      [styleClass]="'w-full'"
      [panelStyleClass]="'w-full'"
      placeholder="{{ 'Select one' | translate }}"
      [(ngModel)]="selectedSiUnit"
      (onLazyLoad)="refreshList($event)"
      (onChange)="select()"
      (onFilter)="onSearch($event)">
      <ng-template #selectedItem>
        <div class="ml-1">{{ selectedSiUnit.name }}</div>
      </ng-template>
      <ng-template #item let-si>
        {{ si.name }}
      </ng-template>
    </p-select>
  }
}
@if (loading) {
  <p-progressSpinner aria-label="Loading" />
}
