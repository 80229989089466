<div
  *ngFor="let row of rows; let i = index; trackBy: trackByIndex"
  class="flex flex-col pb-5 pt-3"
  data-testid="custom-field-value-row__container">
  <div class="field mb-1 mt-1 w-full" data-testid="custom-field-value-row__input-container">
    <span class="p-float-label">
      <input
        type="text"
        pInputText
        class="w-full"
        data-testid="custom-field-value-row__input"
        [(ngModel)]="customValues[i]" />
      <label for="value">{{ 'Custom value' | translate }}</label>
    </span>
  </div>

  @if (rows.length > 1) {
    <div class="field mb-1 w-full pt-1" data-testid="custom-field-value-row__delete-container">
      <div class="flex justify-end flex-wrap card-container">
        <div class="flex items-center justify-center m-2">
          <p-button
            icon="pi pi-trash"
            iconPos="left"
            severity="danger"
            data-testid="custom-field-value-row__delete-button"
            [styleClass]="'w-full'"
            label="{{ 'Delete row' | translate }}"
            [rounded]="true"
            (click)="deleteRow(i)" />
        </div>
      </div>
    </div>
  }
</div>

<div
  class="flex items-center justify-center m-2"
  data-testid="custom-field-value-row__add-container">
  <p-button
    icon="pi pi-plus"
    iconPos="left"
    data-testid="custom-field-value-row__add-button"
    [styleClass]="'w-full'"
    label="{{ 'Add row' | translate }}"
    [rounded]="true"
    (click)="addRow()" />
</div>
